<template>
  <div :class="$style.root">
    <VDataTable
        fixed-header
        height="calc(100vh - 263px)"
        hideDefaultHeader
        :headers="headers"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 20, 50, 100, 500],
          itemsPerPageText: '',
        }"
        :items="items"
        :options.sync="options"
        :server-items-length="count"
        :mobile-breakpoint="0"
        @update:page="page => $router.push({ query: { ...$route.query, page } })"
        @update:items-per-page="size => $router.push({ query: { ...$route.query, size } })"
    >
      <template v-slot:header>
        <thead class="v-data-table-header">
          <tr>
            <th v-for="{text, colspan } in headers" class="text-start" :colspan="colspan" :key="text">{{text}}</th>
          </tr>
          <tr :class="$style.filter" ref="filter" v-if="isReviewer || isSpectator || isGeneralReviewer">
            <th v-for="key in ['id']" :key="key">
              <VTextField
                  dense
                  clearable
                  hide-details
                  :value="$route.query[key]"
                  @input="value => $router.push({ query: { ...$route.query, [key]: value } })"
              />
            </th>
            <th colspan="1" />
            <th>
                <SelectMultiple
                        v-if="statuses"
                        :items="statuses"
                        :value="$route.query['status']"
                        @input="(value) => $router.push({ query: { ...$route.query, status: value } })"
                />
            </th>
            <th colspan="2" />
            <th v-for="key in ['inn', 'name', 'email', 'phone']" :key="key">
              <VTextField
                  dense
                  clearable
                  hide-details
                  :value="$route.query[key]"
                  @input="value => $router.push({ query: { ...$route.query, [key]: value } })"
              />
            </th>
            <th colspan="5" />
          </tr>
        </thead>
      </template>
      <template v-slot:body.prepend>

      </template>
      <template v-slot:item="{ headers, item }">
        <tr :class="rowClassControl(item)">
          <template v-for="({ value }) in headers">
            <td :key="value">
              <div :class="['text-center', 'mt-2', $style.timeline]" v-if="~value.indexOf('timeline')">
                <template v-if="getItem(item, value + '.code') === STATUSES.NO.value">
                  <VChip color="error">
                    <VIcon left>mdi-close</VIcon>
                    Отклонено
                  </VChip>
                </template>
                <template v-if="getItem(item, value + '.code') === STATUSES.YES.value">
                  <VChip color="success">
                    <VIcon left>mdi-check</VIcon>
                    Согласовано
                  </VChip>
                </template>
                <template v-if="getItem(item, value + '.code') === STATUSES.REWORK.value">
                  <VChip color="orange" text-color="white">
                    <VIcon left>mdi-pencil-outline</VIcon>
                    На доработке
                  </VChip>
                </template>
                <template v-if="getItem(item, value + '.code') === STATUSES.WAIT_IN_LINE.value">
                  <VChip color="grey" text-color="white">
                    <VIcon left>mdi-clock-outline</VIcon>
                    Ожидает очереди
                  </VChip>
                </template>
                <template v-if="getItem(item, value + '.code') === STATUSES.WAIT_FOR_DECISION.value">
                  <VChip color="orange" text-color="white">
                    <VIcon left>mdi-clock-outline</VIcon>
                    На проверке
                  </VChip>
                </template>
                <template v-if="!getItem(item, value + '.code')">
                  <VChip color="error" text-color="white">
                    <VIcon left>mdi-eye-off-outline</VIcon>
                    Не участвует
                  </VChip>
                </template>
                <div :class="datesColorControl(getItem(item, value))">
                  <small>{{ getItem(item, value + '.dateCreate') }} <span v-if="getItem(item, value + '.dateExec')"> - {{ getItem(item, value + '.dateExec') }}</span></small>
                </div>
                <div v-if="getItem(item, value + '.reviewers')">
                  <div><small v-for="reviewer in getItem(item, value + '.reviewers')" :key="reviewer.id">{{ reviewer.name }}</small></div>
                </div>
              </div>
              <template v-else-if="~value.indexOf('risksStatuses')">
                <div class="py-2" v-html="getItem(item, value)"></div>
              </template>
              <template v-else-if="value === 'id'">
                <router-link :to="{name: 'pkosmr/CounterpartyDetailView', params: { counterparty: getItem(item, value) }}">{{ getItem(item, value) }}</router-link>
              </template>
              <template v-else-if="value === 'company'">
                <div v-html="getItem(item, value)" />
              </template>
              <template v-else-if="value === 'isInTerritory'">
                {{ getInTerritoryValue(getItem(item, value)) }}
              </template>
              <template v-else>
                {{ ~value.indexOf('sections') ? getItem(item, value + '.name') : getItem(item, value)}}
              </template>
            </td>
          </template>
        </tr>
      </template>
      <template v-slot:item.status.name="{ item: { status } }">
        {{ status.name }} <br />
        {{status.start}} - {{ status.end}}
      </template>
    </VDataTable>
    <VProgressLinear v-if="loading" absolute indeterminate color="primary darken-2" :class="$style.progress" />
  </div>
</template>

<script>
import {forEach, get, indexOf, map} from 'lodash-es';
import { mapGetters } from 'vuex';
import { ROLES } from '@/store/user/enums';
import { STATUSES, BEFORE_EXPIRED_PKO_DAYS } from '@/store/pkosmr/enums';
import SelectMultiple from '@/components/pkosmr/FormRegistryList/components/SelectMultiple.vue';
export default {
  name: 'RegistryList',
  components: {
    SelectMultiple,
  },
  props: {
    items: { type: Array },
    count: { type: Number },
    page: { type: Number },
    size: { type: Number },
    loading: { type: Boolean },
    risks: { type: Array },
  },
  data: function() {
    return {
      options: {
        page: this.page,
        itemsPerPage: this.size
      },
      STATUSES,
    }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
    headers() {
      return [
        { text: 'ID заявки', value: 'id', sortable: false },
        { text: 'Вид квалификации', value: 'qualification', sortable: false },
        { text: 'Статус', value: 'status.name', alias: 'status', sortable: false },
        { text: 'Дата отправки на согласование', value: 'dateSend', sortable: false },
        { text: 'Дата окончания ПКО', value: 'dateStatusEnd', sortable: false },
        { text: 'ИНН', value: 'inn', sortable: false },
        { text: 'Организация', value: 'name', sortable: false },
        { text: 'Электронная почта', value: 'email', sortable: false },
        { text: 'Номер телефона', value: 'phone', sortable: false },
        { text: 'Юр. адрес', value: 'address', sortable: false },
        { text: 'Проверяющий', value: 'timelineManager', sortable: false, align: 'center' },
        { text: 'Проверяющий-юрист', value: 'timelineLawyer', sortable: false, align: 'center' },
        { text: 'Согласующий', value: 'timelineReviewer', sortable: false, align: 'center' },
        { text: 'Утверждающий', value: 'timelineApprover', sortable: false, align: 'center' },
      ]
    },
    statuses() {
      return [
        {text: 'Подготовка', value: STATUSES.PREPARE.value },
        {text: 'На согласовании', value: STATUSES.ON_APPROVAL.value },
        {text: 'На доработке', value: STATUSES.ON_REWORK.value },
        {text: 'Не пройдено', value: STATUSES.PKO_NOT_PASSED.value },
        {text: 'Согласовано', value: STATUSES.PKO_PASSED.value },
      ]
    },
    markSections() {
      const result = {};
      forEach(this.items, ({ sections }) => {
        forEach(sections, ({ name }, code) => {
          result[code] = name;
        });
      })
      return result;
    },
    isReviewer() {
      return this.hasRole([ROLES.REVIEWER, ROLES.FIRST_REVIEWER, ROLES.SECOND_REVIEWER, ROLES.LAWYER, ROLES.APPROVER], 'pkosmr');
    },
    isSpectator() {
      return this.hasRole(ROLES.SPECTATOR, 'pkosmr');
    },
    isGeneralReviewer() {
      return this.hasRole([ROLES.GENERAL_REVIEWER], 'pkosmr');
    },
    risksList() {
      return map(this.risks, (item) => ({
        text: get(item, 'name'),
        value: get(item, 'code'),
      }))
    }
  },
  methods: {
    onResize() {
      if (this.$refs.filter)
        this.$refs.filter.style.top = this.$refs.filter.previousElementSibling.offsetHeight + 'px';
    },
    getItem(item, path) {
      return get(item, path);
    },
    rowClassControl({ status, dateStatusEnd }) {
      //«Зеленый» - анкета действующая
      // «Оранжеый» – срок действия анкеты истекает через 45 календарных дней
      // «Красный» - срок действия анкеты истек.
      if(STATUSES.PKO_NOT_PASSED.value === status.code) return 'red lighten-4';
      if (STATUSES.PKO_PASSED.value === status.code && !dateStatusEnd.length) return 'green lighten-4';
      if (!dateStatusEnd.length) return '';

      const dateEnd = Date.parse(dateStatusEnd.split('.').reverse().join('-'));
      const now = new Date();
      const diff = dateEnd - now;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));

      if(!!~indexOf([STATUSES.PKO_PASSED.value, STATUSES.ON_APPROVAL.value], status.code) && days <= BEFORE_EXPIRED_PKO_DAYS) return 'orange lighten-4';
      if(!!~indexOf([STATUSES.PKO_PASSED.value, STATUSES.ON_APPROVAL.value], status.code) && days > BEFORE_EXPIRED_PKO_DAYS) return 'green lighten-4';
      return '';
    },
    datesColorControl({ code, dateCreate }) {
      //Если с даты создания прошло больше 7 дней, то подсвечиваем красным
      if (code !== STATUSES.WAIT_FOR_DECISION) return '';
      if (!dateCreate) return '';

      const date = Date.parse(dateCreate.split('.').reverse().join('-'));
      const now = new Date();
      const diff = now - date;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      if (days > 7) return 'red--text';
    },
    getInTerritoryValue(val) {
      if (val === null) return '';
      return val ? 'на' : 'вне';
    },
  },
  watch: {
    markSections: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.onResize();
        })
      }
    }
  }
}
</script>

<style module lang="scss">
.root {
  position: relative;
  .filter {
    th {
      top: inherit !important;
    }
  }
  th {
    width: 220px !important;
    &:nth-child(1) {
      width: 120px !important;
    }
  }
  td {
    min-width: 220px !important;
    &:nth-child(1) {
      width: 120px !important;
      min-width: 0 !important;
    }
  }
}
.timeline {
  min-height: 60px;
}
.progress {
  top: 0;
  position: absolute;
  width: 100%;
  z-index: 10;
}
</style>
